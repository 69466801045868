export default {
  Boolean: [
    {
      name: 'FALSE',
      value: 1,
      description: '否',
    },
    {
      name: 'TRUE',
      value: 0,
      description: '是',
    },
  ],
  Type: [
    {
      name: 'company',
      value: 1,
      description: '公司资讯',
    },
    {
      name: 'business',
      value: 2,
      description: '行业资讯',
    },
  ],
  Program: [
    {
      value: 1,
      description: '深度学习和AI解决方案',
    },
    // {
    //   value: 6,
    //   description: '硬件加速器集群',
    // },
    {
      value: 2,
      description: '赋创 Hadoop',
    },
    {
      value: 3,
      description: '高性能计算（HPC）解决方案',
    },
    {
      value: 5,
      description: 'Ceph存储解决方案',
    },
  ]
}
