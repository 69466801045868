import { Image } from 'components'

export default function View ({ route, store, external }) {
  React.useEffect(() => scrollTo(0, 0), [])

  const getContent = R.cond([
    [R.equals(1), () => {
      return <article>
        <h1 className='py-4 pt-10 text-lg text-center font-bold desktop:pt-16 desktop:text-2xl'>赋创人工智能和深度学习解决方案</h1>

        <ul>
          <li className='odd:bg-gray-100 bg-white p-4 desktop:p-16 desktop:px-64'>
            <h3 className='py-2 font-bold desktop:text-lg'>利用赋创深度学习技术来训练人工智能</h3>
            <p className='leading-8 desktop:leading-loose desktop:text-base'>深度学习是包含人工智能（AI）和机器学习（ML），是计算机科学中最先进的程序，它实现了多层人工神经网络来完成过于复杂而无法编程的任务。例如，谷歌地图每天处理数百万个数据点，以找出最佳旅行路线，或预测到达所需目的地的时间。深度学习包括两部分——训练和推理。深度学习的训练部分涉及处理尽可能多的数据点，使神经网络能够自行“学习”特征并修改自身以完成图像识别、语音识别等任务。推理部分是指采用经过训练的模型并使用它来做出有用的预测和决策。</p>
          </li>

          <li className='odd:bg-gray-100 bg-white p-4 desktop:p-16 desktop:px-64'>
            <h3 className='py-2 font-bold desktop:text-lg'>人工智能与深度学习平台</h3>
            <p className='leading-8 desktop:leading-loose desktop:text-base'>我们的解决方案提供定制的深度学习框架安装，因此最终用户无需任何 GPU 编程即可直接开始部署深度学习项目。我们的解决方案提供深度学习框架的定制安装，包括 TensorFlow、Caffe2、MxNet、Chainer、Microsoft Cognitive Toolkit 等。</p>
            <p className='leading-8 desktop:leading-loose desktop:text-base'>赋创人工智能（AI）和深度学习解决方案提供了完整的人工智能（AI）/深度学习软件堆栈。下面是端到端完全集成提供的解决方案：</p>

            {store.responsive.isDesktop ? (
              <table className='border-collapse mt-6 w-full'>
                <thead>
                  <tr className='text-white' style={{ background: '#154a9a' }}>
                    <th className='p-4 border border-white text-center text-xl' colSpan={3}>人工智能和深度学习软件堆栈</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='text-white' style={{ background: '#239ee0' }}>
                    <td className='p-4 border border-white text-center text-lg font-bold' rowSpan={4}>深度学习环境</td>
                    <td className='p-4 border border-white text-center text-base'>框架</td>
                    <td className='p-4 border border-white text-center text-base'>Caffe, Caffe2, Caffe-MPI, Chainer, Microsoft CNTK, Keras, MXNet</td>
                  </tr>
                  <tr className='text-white' style={{ background: '#45ade4' }}>
                    <td className='p-4 border border-white text-center text-base'>知识库</td>
                    <td className='p-4 border border-white text-center text-base'>cnDNN, NCCL, cuBLAS</td>
                  </tr>
                  <tr className='text-white' style={{ background: '#71bfea' }}>
                    <td className='p-4 border border-white text-center text-base'>用户访问</td>
                    <td className='p-4 border border-white text-center text-base'>NVIDIA DIGITS</td>
                  </tr>
                  <tr className='text-white' style={{ background: '#8ecdee' }}>
                    <td className='p-4 border border-white text-center text-base'>操作系统</td>
                    <td className='p-4 border border-white text-center text-base'>Ubuntu, Docker, NVIDIA Docker</td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <table className='border-collapse'>
                <thead>
                  <tr className='text-white' style={{ background: '#154a9a' }}>
                    <th colSpan={3} className='p-2 border border-white text-center text-lg'>人工智能和深度学习软件堆栈</th>
                  </tr>
                  <tr className='text-white' style={{ background: '#239ee0' }}>
                    <th colSpan={3} className='p-2 border border-white text-center'>深度学习环境</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='text-white' style={{ background: '#239ee0' }}>
                    <td className='p-2 border border-white text-center text-xs'>框架</td>
                    <td className='p-2 border border-white w-full text-center text-xs'>Caffe, Caffe2, Caffe-MPI<br/> Chainer, Microsoft CNTK, Keras<br/> MXNet</td>
                  </tr>
                  <tr className='text-white' style={{ background: '#45ade4' }}>
                    <td className='p-2 border border-white text-center text-xs'>知识库</td>
                    <td className='p-2 border border-white text-center text-xs'>cnDNN, NCCL, cuBLAS</td>
                  </tr>
                  <tr className='text-white' style={{ background: '#71bfea' }}>
                    <td className='p-2 border border-white text-center text-xs'>用户访问</td>
                    <td className='p-2 border border-white text-center text-xs'>NVIDIA DIGITS</td>
                  </tr>
                  <tr className='text-white' style={{ background: '#8ecdee' }}>
                    <td className='p-2 border border-white text-center text-xs'>操作系统</td>
                    <td className='p-2 border border-white text-center text-xs'>Ubuntu, Docker, NVIDIA Docker</td>
                  </tr>
                </tbody>
              </table>
            )}
          </li>

          <li className='odd:bg-gray-100 bg-white p-4 desktop:p-16 desktop:px-64'>
            <h3 className='py-2 font-bold desktop:text-lg'>赋创人工智能（AI）和深度学习解决方案的优势</h3>

            <ul className='space-y-4 desktop:mt-8 desktop:text-base desktop:grid desktop:grid-cols-2 desktop:gap-x-40 desktop:gap-y-8'>
              <li>
                <p className='font-bold desktop:mb-2'>计算强国</p>
                <p className='leading-6 desktop:leading-loose'>• 赋创人工智能（AI）和深度学习集群由赋创超服务器系统提供动力，这些系统密度高，计算能力强。该集群采用来自赋创合作伙伴NVIDIA 的最新 GPU。每个计算节点使用 NVIDIA Tesla V100 GPU。</p>
              </li>

              <li>
                <p className='font-bold desktop:mb-2'>高密度并行计算</p>
                <p className='leading-6 desktop:leading-loose'>• 多达 32 个 GPU 和高达 1TB 的 GPU 内存，可实现最大的并行计算性能，从而减少深度学习工作负载的训练时间。</p>
              </li>

              <li>
                <p className='font-bold desktop:mb-2'>使用 NVLink 增加带宽</p>
                <p className='leading-6 desktop:leading-loose'>• 利用 NVLinkTM，可以更快地实现 gpu 和 gpu 之间的通信，进一步提高系统在繁重的深度学习工作负载下的性能。</p>
              </li>

              <li>
                <p className='font-bold desktop:mb-2'>使用 Tensor Core加快处理速度</p>
                <p className='leading-6 desktop:leading-loose'>• NVIDIA Tesla V100 GPU 使用 Tensor Core 架构。Tensor核心包含深度学习支持，可以提供多达125 Tensor TFLOPS 用于训练和推理应用。</p>
              </li>

              <li>
                <p className='font-bold desktop:mb-2'>可扩展设计</p>
                <p className='leading-6 desktop:leading-loose'>• 具有 100G IB EDR 结构的横向扩展架构，具有极强的可扩展性以适应未来的增长。</p>
              </li>

              <li>
                <p className='font-bold desktop:mb-2'>Rapid Flash Xtreme (RFX)-高性能全闪存 NVMe 存储器</p>
                <p className='leading-6 desktop:leading-loose'>• RFX 是顶级的完整存储系统，为人工智能和深度学习应用程序开发和完整测试，包括赋创 BigTwinTM 和 WekaIO 并行存档系统。</p>
              </li>
            </ul>
          </li>

          <li className='odd:bg-gray-100 bg-white p-4 desktop:p-16 desktop:px-64'>
            <h3 className='py-2 font-bold desktop:text-lg'>人工智能与深度学习参考体系结构配置</h3>
            <p className='leading-8 desktop:text-base desktop:leading-loose'>赋创目前提供以下完整的解决方案，经过彻底的测试和准备就绪。这些集群可以扩展和缩小，以满足您的深度学习项目的需求。</p>

            <div className='flex items-end'>
              <div className='flex-1 flex justify-end'>
                <Image className='w-full max-w-xl object-cover' src={store.config.loadImg('/resources/cover-1.png')} />
              </div>

              <div className='flex-1 flex justify-start'>
                <Image className='w-full max-w-xl object-cover' src={store.config.loadImg('/resources/cover-2.png')} />
              </div>
            </div>
          </li>
        </ul>
      </article>
    }],

    [R.equals(2), () => {
      return <article>
        <h1 className='py-4 pt-10 text-lg text-center font-bold desktop:pt-16 desktop:text-2xl'>赋创 Hadoop 解决方案</h1>

        <ul>
          <li className='odd:bg-gray-100 bg-white p-4 desktop:p-16 desktop:px-64'>
            <h3 className='py-2 font-bold desktop:text-lg'>新的挑战</h3>
            <p className='leading-8 desktop:text-base desktop:leading-loose'>不断变化的应用程序，从结构化的、非结构化的、半结构的数据中，都有大量的信息被驱动着。传统的IT基础设施并不是为了处理社交媒体网络、移动应用程序、机器传感器和科学研究等产生的数据的多样性、速度和容量而构建的。对于企业来说，利用大数据分析已经不再是什么时候的问题了，如何解决这个问题呢。Hadoop就是为了这个目的而诞生的，它设计用于经济高效地存储和处理大量数据。它可以线性扩展到数以千计的服务器和 PB 的存储。</p>
          </li>
          <li className='odd:bg-gray-100 bg-white p-4 desktop:p-16 desktop:px-64'>
            <h3 className='py-2 font-bold desktop:text-lg'>解决方案</h3>
            <div className='space-y-2'>
              <p className='leading-8 desktop:text-base desktop:leading-loose'>引入赋创 Hadoop 集群，一系列优化的大型数据解决方案，提供高性能、高可靠性和高可扩展性。赋创 Hadoop 解决方案是完全集成的，完全优化和完全测试的交接集群与灵活的支持套装可满足客户的特定要求。</p>
              <p className='leading-8 desktop:text-base desktop:leading-loose'>赋创 Hadoop 集群功能通过广泛的工程设计、验证和测试，通过大量的设计、检验和检测，为业界所公认的高密度计算和存储服务器提供了最佳的选择。经过验证的配置可以考虑设计和部署一个真正可扩展的大型数据计算和存储基础结构，以满足最苛刻的企业 IT 和数据中心环境。</p>
            </div>
          </li>

          <li className='odd:bg-gray-100 bg-white p-4 desktop:p-16 desktop:px-64'>
            <h3 className='py-2 font-bold desktop:text-lg'>赋创优势</h3>

            <ul className='space-y-2 desktop:text-base desktop:leading-loose'>
              <li>以满足各种工作负载的最佳服务器和群集配置为基础的设计</li>
              <li>基于广泛的实验室测试和大规模生产部署的行之有效的解决方案</li>
              <li>通过业界领先的服务器和存储平台，实现最佳的性价比和最佳的价格／容量</li>
              <li>端到端的完整的 Hadoop 集群，具有完全集成了硬件、软件和全球支持</li>
              <li>基于最新技术（如 SkyLake CPU、SAS3、NvMe、Optane 驱动器）的高级体系结构</li>
              <li>具有双 10GbE／25GbE／40GbE／100GbE 选项的增强的网络性能和冗余性</li>
              <li>自动化的完整体测试可确保生产质量和交付时间</li>
            </ul>
          </li>

          <li className='odd:bg-gray-100 bg-white p-4 desktop:p-16 desktop:px-64'>
            <h3 className='py-2 font-bold desktop:text-lg'>完全集成的 Hadoop 群集</h3>
            <p className='py-2 font-bold desktop:text-base desktop:leading-loose'>主要特点和优势：</p>

            <div className='grid gap-x-24 grid-cols-1 desktop:grid-cols-2'>
              <ul className='space-y-2 desktop:text-base desktop:leading-loose'>
                <li>为容量、计算或 IO 性能优化而构建的群集配置的目的</li>
                <li>无单点故障的高可用性名称节点设计</li>
                <li>专为 Spark 和其他内存设计的大型内存选项, 低滞后时间计算</li>
                <li>为超大的部署而设计的超大规模服务器平台</li>
                <li>高密度计算、存储和内存设计, 实现最佳效率和最低 TCO</li>
                <li>灵活的网络交换机选项，每个机架上有 1 或 2x 10G／25G／100G 交换机。</li>
                <li>性价比高的 14U 机架设计，是概念测试环境验证的理想选择</li>
                <li>符合任何数据中心环境的标准 42U 机架设计和灵活的 PDU 选项</li>
                <li>支持钛金级（96% +）效率-冗余电源与 PMBus</li>
                <li>内置 IPMI 和 SMC OOB（带外管理）套件进行自动化群集管理</li>
                <li>完全集成，完全配置和完全测试与 Hadoop 分布您的选择</li>
                <li>可用于无风险采购体验的概念测试集群的证明</li>
              </ul>

              <div className='py-2 flex items-center'>
                <div className='flex-none'>
                  <Image className='max-h-96 desktop:max-h-full object-cover' src={store.config.loadImg('/resources/cover-3.png')} />
                </div>

                <div className='p-2 flex-auto desktop:px-8'>
                  <ul className='space-y-2 text-xs text-gray-600 desktop:text-sm desktop:leading-loose'>
                    <li className='p-2 bg-gray-100 desktop:px-6'>
                      <p>1 或 2x 48 端口 10G SFP+／10GBase-T／25GbE</p>
                      <p>1 或 2x 32 端口 100GbE，1x 48 端口交换机，GbE</p>
                    </li>

                    <li className='p-2 bg-gray-100 desktop:px-6'>
                      <p>3x 管理节点 1U DP Xeon Skylake 41xx／51xx</p>
                    </li>

                    <li className='p-2 bg-gray-100 desktop:px-6'>
                      <p>1x 管理节点 1U UP SKYLAKE 41xx／51xx</p>
                    </li>

                    <li className='p-2 bg-gray-100 desktop:px-6'>
                      <p>优化的数据节点 2U SSG, 2U BigTwin 或 4U FatTwin 采用 SKYLAKE 41xx／51xx／61xx／81xx 处理器，每节点双 10G／25G／40G, 2.5" 和 3.5" HDD 选项</p>
                    </li>

                    <li className='p-2 bg-gray-100 desktop:px-6'>
                      <p>标准 42U 机架，配有计量 PDU可提供机架自定义选项</p>
                    </li>

                    <li className='p-2 bg-gray-100 desktop:px-6'>
                      <p>集成服务包括完整的群集刻录和测试、BIOS 和固件更新、网络配置、预安装 Hadoop 选择分发和完整群集</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>

          {store.responsive.isDesktop == false ? null : <li className='odd:bg-gray-100 bg-white p-4 desktop:p-16 desktop:px-64'>
            <h3 className='py-2 font-bold desktop:text-lg'>Hadoop 群集技术规格</h3>

            <table className='mt-8 w-full table-auto border-collapse text-center text-base'>
              <tr className='bg-blue-900 text-white font-bold'>
                <td className='border border-gray-300 p-4'></td>
                <td className='border border-gray-300 p-4'>High Capacity</td>
                <td className='border border-gray-300 p-4'>IO Optimized</td>
                <td className='border border-gray-300 p-4'>High Density Compute</td>
                <td className='border border-gray-300 p-4'>Balanced</td>
              </tr>

              <tr className='bg-white text-gray-800'>
                <td className='border border-gray-300 p-4 font-bold'>Model SKU</td>
                <td className='border border-gray-300 p-4'>SRS-HCSG18-HADP-01</td>
                <td className='border border-gray-300 p-4'>SRS-IOBT32-HADP-01</td>
                <td className='border border-gray-300 p-4'>SRS-HDFT36-HADP-01</td>
                <td className='border border-gray-300 p-4'>SRS-BLSG37-HADP-01</td>
              </tr>

              <tr className='bg-blue-100 text-gray-800'>
                <td className='border border-gray-300 p-4 font-bold'>Data Node</td>
                <td className='border border-gray-300 p-4'>SSG-6029P-E1CR24L</td>
                <td className='border border-gray-300 p-4'>SYS-2029BT-HNR</td>
                <td className='border border-gray-300 p-4'>SYS-F629P3-RC0B</td>
                <td className='border border-gray-300 p-4'>SSG-6019P-ACR12L</td>
              </tr>

              <tr className='bg-blue-900 text-white font-bold'>
                <td className='border border-gray-300 p-4'>Data Node (Qty)</td>
                <td className='border border-gray-300 p-4'>18</td>
                <td className='border border-gray-300 p-4'>32</td>
                <td className='border border-gray-300 p-4'>36</td>
                <td className='border border-gray-300 p-4'>37</td>
              </tr>

              <tr className='bg-white text-gray-800'>
                <td className='border border-gray-300 p-4 font-bold' rowSpan={4}>Model SKU</td>
                <td className='border border-gray-300 p-4'>2U SuperStorage</td>
                <td className='border border-gray-300 p-4'>2U BigTwin</td>
                <td className='border border-gray-300 p-4'>4U FatTwin</td>
                <td className='border border-gray-300 p-4'>1U SuperStorage</td>
              </tr>

              <tr className='bg-blue-100 text-gray-800'>
                <td className='border border-gray-300 p-4'>2x SKL 4114 2P 10C/20T 2.2G 85W</td>
                <td className='border border-gray-300 p-4'>2x SKL 5118 4/2P 12C/24T 2.3G 105W</td>
                <td className='border border-gray-300 p-4'>2x SKL 6130 4/2P 16C/32T 2.1G 125W</td>
                <td className='border border-gray-300 p-4'>2x SKL 5118 4/2P 12C/24T 2.3G 105W</td>
              </tr>

              <tr className='bg-white text-gray-800'>
                <td className='border border-gray-300 p-4'>128GB</td>
                <td className='border border-gray-300 p-4'>192GB</td>
                <td className='border border-gray-300 p-4'>256GB</td>
                <td className='border border-gray-300 p-4'>128GB</td>
              </tr>

              <tr className='bg-blue-100 text-gray-800'>
                <td className='border border-gray-300 p-4'>24 Bay 3.5"</td>
                <td className='border border-gray-300 p-4'>6 Bay 2.5"</td>
                <td className='border border-gray-300 p-4'>8 Bay 3.5"</td>
                <td className='border border-gray-300 p-4'>12 Bay 3.5"</td>
              </tr>

              <tr className='bg-white text-gray-800'>
                <td className='border border-gray-300 p-4 font-bold'>Total Data Drive</td>
                <td className='border border-gray-300 p-4'>432</td>
                <td className='border border-gray-300 p-4'>448 (NVMe)*</td>
                <td className='border border-gray-300 p-4'>288</td>
                <td className='border border-gray-300 p-4'>444</td>
              </tr>

              <tr className='bg-blue-100 text-gray-800'>
                <td className='border border-gray-300 p-4 font-bold'>Total Cores</td>
                <td className='border border-gray-300 p-4'>360</td>
                <td className='border border-gray-300 p-4'>768</td>
                <td className='border border-gray-300 p-4'>1152</td>
                <td className='border border-gray-300 p-4'>888</td>
              </tr>

              <tr className='bg-white text-gray-800'>
                <td className='border border-gray-300 p-4 font-bold'>Total Memory</td>
                <td className='border border-gray-300 p-4'>2.3TB</td>
                <td className='border border-gray-300 p-4'>6.144TB</td>
                <td className='border border-gray-300 p-4'>9.2TB</td>
                <td className='border border-gray-300 p-4'>4.7TB</td>
              </tr>

              <tr className='bg-blue-100 text-gray-800'>
                <td className='border border-gray-300 p-4 font-bold'>Total Storage</td>
                <td className='border border-gray-300 p-4'>3.45PB (8TB)</td>
                <td className='border border-gray-300 p-4'>1.792PB (4TB)</td>
                <td className='border border-gray-300 p-4'>1.15PB (4TB)</td>
                <td className='border border-gray-300 p-4'>2.66PB (6TB)</td>
              </tr>

              <tr className='bg-blue-900 text-white font-bold'>
                <td className='border border-gray-300 p-4'>Name Node</td>
                <td className='border border-gray-300 p-4'>3x 1U WIO</td>
                <td className='border border-gray-300 p-4'>3x 1U WIO</td>
                <td className='border border-gray-300 p-4'>3x 1U WIO</td>
                <td className='border border-gray-300 p-4'>3x 1U WIO</td>
              </tr>

              <tr className='bg-white text-gray-800'>
                <td className='border border-gray-300 p-4 font-bold'>Switches</td>
                <td className='border border-gray-300 p-4'>1x 48PT 25GBase-T</td>
                <td className='border border-gray-300 p-4'>2x 32PT 40G</td>
                <td className='border border-gray-300 p-4'>1x 48PT GbE</td>
                <td className='border border-gray-300 p-4'>1x 48PT10G SFP+</td>
              </tr>

              <tr className='bg-blue-100 text-gray-800'>
                <td className='border border-gray-300 p-4 font-bold w-64'>Cabinet (W x H x D)</td>
                <td className='border border-gray-300 p-4 text-left' colSpan={4}>42U 23.5 x 82.4 x 48</td>
              </tr>

              <tr className='bg-white text-gray-800'>
                <td className='border border-gray-300 p-4 font-bold'>PDU</td>
                <td className='border border-gray-300 p-4 text-left' colSpan={4}>2x 50A 208 3-Phase Metered PDU</td>
              </tr>

              <tr className='bg-blue-100 text-gray-800'>
                <td className='border border-gray-300 p-4 text-left' colSpan={5}>*ADD 32 Bay 2.5" Drives x8 JBOF = 256 NVMe</td>
              </tr>
            </table>
          </li>}
        </ul>
      </article>
    }],

    [R.equals(3), () => {
      return <article>
        <h1 className='py-4 pt-10 text-lg text-center font-bold desktop:pt-16 desktop:text-2xl'>高性能计算（HPC）解决方案</h1>

        <ul>
          <li className='odd:bg-gray-100 bg-white p-4 desktop:p-16 desktop:px-64'>
            <h3 className='py-2 font-bold desktop:text-lg'>新的挑战</h3>
            <p className='leading-8 desktop:text-base desktop:leading-loose'>赋创参考体系架构旨在满足您独特的 HPC 需求。我们的优势包括广泛的构建块，从主板设计到系统配置，完全集成的机架和液体冷却系统。我们专注于为客户提供量身定制的解决方案。</p>
          </li>

          <li className='odd:bg-gray-100 bg-white p-4 desktop:p-16 desktop:px-64'>
            <h3 className='py-2 font-bold desktop:text-lg'>企业 HPC 参考设计</h3>

            <div className='grid grid-cols-1 desktop:grid-cols-2 gap-x-16 items-center'>
              <Image className='w-full object-cover' src={store.config.loadImg('/resources/cover-4.jpg')} />

              <div>
                <p className='leading-8 desktop:text-base desktop:leading-loose'>
                  此功能参考体系结构是为通用计算密集型工作负载而构建的，非常适合于企业HPC用例，如数字制造、金融风险管理、医学研究、石油和天然气勘探等。
                </p>

                <p className='leading-8 desktop:text-base desktop:leading-loose'>
                  服务器: SuperBlade® SBI-420P-1C2N, 每个机架最多80个节点<br/>
                  多达 6400 个处理器核（Intel® Xeon®), 320TB DDR4-3200，每个机架 160 个 2.5 英寸驱动器托架<br/>
                  使用内置 IB HDR 和 25G 交换机模块减少布线<br/>
                  热插拔节点、交换机模块、PSU 和风扇模块具有良好的可维护性<br/>
                  高度可扩展，在 non-blocking IB 结构中，每个群集最多 10 个机架和 800 个计算节点<br/>
                </p>
              </div>
            </div>
          </li>

          <li className='odd:bg-gray-100 bg-white p-4 desktop:p-16 desktop:px-64'>
            <h3 className='py-2 font-bold desktop:text-lg'>科学研究超级计算机参考设计</h3>

            <div className='grid grid-cols-1 desktop:grid-cols-2 gap-x-16 items-center'>
              <Image className='w-full object-cover desktop:order-last' src={store.config.loadImg('/resources/cover-5.jpg')} />

              <div>
                <p className='leading-8 desktop:text-base desktop:leading-loose'>经过验证的高性能计算机设计为许多科学研究项目而建造，这种超级计算机设计在天气模拟、核反应物理模拟、基因测序、地球和空间发现等方面具有高度的可扩展性。</p>

                <p className='leading-8 desktop:text-base desktop:leading-loose'>
                  服务器: 如 16 TwinPro® SYS-220TP-HTTR 或 BigTwin® AS -2124BT-HTR, 每个机架 64 个节点<br/>
                  • 支持 AMD EPYC™ 高达 8192 核 (BigTwin）或每个机架配备 Intel Xeon 5120 核（TwinPro）<br/>
                  • 可选 DLC 液体冷却，40KW CDU 至 80KW<br/>
                  • 极易扩展，在 2:1 OPA 结构中，每个群集最多 30 个机架和 1920 个节点<br/>
                  • TwinPro平衡了每一个节点与每一个插口性能，BigTwin 的每节点双 socket 设计实现了十足的性能。<br/>
                </p>
              </div>
            </div>
          </li>

          {/*<li className='odd:bg-gray-100 bg-white p-4 desktop:p-16 desktop:px-64'>*/}
          {/*  <h3 className='py-2 font-bold desktop:text-lg'>AI，深度学习 HPC 参考设计</h3>*/}

          {/*  <div className='grid grid-cols-1 desktop:grid-cols-2 gap-x-16 items-center'>*/}
          {/*    <Image className='w-full object-cover' src={store.config.loadImg('/resources/cover-6.jpg')} />*/}

          {/*    <div>*/}
          {/*      <p className='leading-8 desktop:text-base desktop:leading-loose'>我们的可扩展 AI+HPC 即插即用设计具有最新的突破性 AI 功能，能够释放前所未有的科学发现速度，让企业专注于构建改变游戏规则的 AI 应用程序，实现自动驾驶、自然语言处理等。</p>*/}

          {/*      <p className='leading-8 desktop:text-base desktop:leading-loose'>*/}
          {/*        服务器: 例如 5 个 4U 8-GPU 系统, SYS-420GP-TNAR 或 AS -4124GO-NART, 带 NVIDIA® HGX A100 和 NVLink®<br/>*/}
          {/*        • 多达 80 个 NVIDIA A100 GPU，每个机架具有 40 PB 的 AI 性能<br/>*/}
          {/*        • 每个机架最多支持 40TB DDR4-3200 和 30 个热插拔 Gen4 NVMe 驱动程序<br/>*/}
          {/*        • 高速 GPU 互连，每个节点 8 个 200G IB HDR+2 个 100G 以太网端口<br/>*/}
          {/*        • 高度可扩展性，每个群集最多 100 个节点和 800 个 A100 GPU，采用非阻塞 IB HDR 结构，可选择 D2C 液体冷却*/}
          {/*      </p>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</li>*/}
        </ul>
      </article>
    }],

    [R.equals(4), () => {
      return <article>
        <h1 className='py-4 pt-10 text-lg text-center font-bold desktop:pt-16 desktop:text-2xl'>赋创软件定义存储和內存解决方案</h1>

        <ul>
          <li className='odd:bg-gray-100 bg-white p-4 desktop:p-16 desktop:px-64'>
            <h3 className='py-2 font-bold desktop:text-lg'>新的挑战</h3>
            <p className='leading-8 desktop:text-base desktop:leading-loose'>存储环境演变从高价专有的硬件和软件解决方案到开放行业标准硬件效益显著：减少供应商锁定,显著的开放新技术创新，如所有 NVMe 解决方案。赋创的广泛的存储平台选择主导存储供应商和主要的超大型数据中心。</p>
            <p className='leading-8 desktop:text-base desktop:leading-loose'>赋创提供了显著的效益到软件定义存储解决方案：最高效率－大容量 1U-4U 规格。引领行业 95% 高效白金级电源</p>
            <p className='leading-8 desktop:text-base desktop:leading-loose'>最高性能和可扩展性－所有 NVMe 支持 with 混合扩展器和提供最高吞吐量</p>
            <p className='leading-8 desktop:text-base desktop:leading-loose'>关键任务可靠性－能够充分冗余容错运行补给, 风扇和冗余主板远程管理</p>
            <p className='leading-8 desktop:text-base desktop:leading-loose'>经久考验的兼容性－经实验室确认硬件配件优化高效能循环工作负载</p>
          </li>
        </ul>
      </article>
    }],

    [R.equals(5), () => {
      return <article>
        <h1 className='py-4 pt-10 text-lg text-center font-bold desktop:pt-16 desktop:text-2xl'>赋创® Ceph 的总体解决方案</h1>

        <ul>
          <li className='odd:bg-gray-100 bg-white p-4 desktop:p-16 desktop:px-64'>
            <p className='py-2 font-bold desktop:text-lg'>为可靠的大规模存储部署而设计</p>
            <p className='py-1 leading-8 desktop:text-base desktop:leading-loose'>大规模的发展如 OpenStack 云计算平台,业务数据存储已经永远改变了。扩展软件定义存储解决方案正在迅速成为普遍选择的实现模型大规模部署。灵活的商业模式需要同样在敏捷平台基础上构建的。代替过去的专有数据存储与灵活的服务器硬件，云供应商有更多对环境的控制，导致更好的顾客价值。赋创已经接受了这种变化，提供最广泛选择的服务器硬件行业。</p>
          </li>

          <li className='odd:bg-gray-100 bg-white p-4 desktop:p-16 desktop:px-64'>
            <div className='grid grid-cols-1 desktop:grid-cols-2 gap-x-16 items-center'>
              <div>
                <p className='py-2 font-bold desktop:text-lg'>准备好部署配置</p>
                <p className='py-1 leading-8 desktop:text-base desktop:leading-loose'><strong>角色特定集群配置</strong>－交钥匙集群配置提供性能、容量和密度适合流行的应用程序工作负载。内存和网络选项很容易定制以满足特定的需求</p>
                <p className='py-1 leading-8 desktop:text-base desktop:leading-loose'><strong>优化网络配置</strong>－集群齿条水平集成提供了简化部署的 Red Hat Ceph 存储和基础设施，使用简易方法不能实现一致性</p>
                <p className='py-1 leading-8 desktop:text-base desktop:leading-loose'><strong>存储/媒体比率符合用户应用程序</strong>－部署 flash 和旋转磁性媒体允许解决方案满足工作负载调优性能和密度的目标</p>
              </div>

              <Image className='w-full object-cover' src={store.config.loadImg('/resources/cover-7.png')} />
            </div>
          </li>

          <li className='odd:bg-gray-100 bg-white p-4 desktop:p-16 desktop:px-64'>
            <p className='py-2 font-bold desktop:text-lg'>基于对象的存储块和对象接口</p>
            <p className='py-1 leading-8 desktop:text-base desktop:leading-loose'>组织更喜欢基于对象的存储当部署大规模存储系统,因为它更有效地存储数据。基于对象的存储系统单独对象名称空间从底层存储硬件，这简化了数据迁移。</p>

            <p className='py-2 font-bold desktop:text-lg'>Ceph 存储的区别</p>
            <p className='py-1 leading-8 desktop:text-base desktop:leading-loose'>Ceph 的粉碎算法释放客户端访问限制，通过集中数据表映射通常用于扩展存储。Ceph 不断实现平衡数据在 cluster-delivering 一致的性能和巨大的扩展。Ceph 提供积极的自我修复功能的弹性水平。数据冗余是通过复制或擦除编码允许极其高效的产能利用率。</p>

            <p className='py-2 font-bold desktop:text-lg'>赋创/Ceph Ready 系统</p>
            <p className='py-1 leading-8 desktop:text-base desktop:leading-loose'>赋创在最困难的IT挑战领先于同行业界Ceph Ready系统和机架为开源社区提供了一个裸机解决方案，并通过Red Hat Ceph Storage下的密集测试进行了验证。</p>
            <p className='py-1 leading-8 desktop:text-base desktop:leading-loose'>Ceph测试是一个持续的过程，使用社区版本，如Firefly、Hammer、Jewel、Luminous等，针对各种操作系统，如Ubuntu和CentOS。此外，还使用企业版Ceph，如SUSE Enterprise Storage和Red Hat Ceph。关于您的具体要求，请与您的销售代表联系。</p>
          </li>
        </ul>
      </article>
    }],

    [R.equals(6), () => {
      return <article>
        <h1 className='py-4 px-12 pt-10 text-lg text-center font-bold desktop:pt-16 desktop:text-2xl'>某工业大学集成电路学院硬件加速器集群方案</h1>

        <div className='p-8 desktop:px-32'>
          <Image className='w-full object-cover' src={store.config.loadImg('/resources/cover-10.png')} />
        </div>

        <ul>
          <li className='odd:bg-gray-100 bg-white p-4 desktop:p-16 desktop:px-64'>
            <p className='py-2 font-bold desktop:text-xl'>项目背景</p>
            <p className='py-1 leading-8 desktop:text-base desktop:leading-loose'>人工智能（AI）辅助集成电路布局布线是一项具有重要应用潜力的研究领域。它结合了人工智能和电路设计的技术，旨在通过智能化算法和优化方法提高电路布局和布线的效率和质量。现代电路设计中的布局布线任务变得越来越复杂。集成电路的规模不断增大，而设计周期却要求更短。同时，设计要求还包括减小功耗、提高性能、减少噪声等。这些复杂性要求了更高效、智能化的布局布线方法。</p>
            <p className='py-1 leading-8 desktop:text-base desktop:leading-loose'>相较于传统手工布局布线方法，AI辅助布局布线的研究所需要的计算，内存及数据存储量都非常大，对于模型训练及优化算法中的并行计算加速的要求更高，鉴于以上对于算力高需求，现需要打造一套硬件加速集群，以支撑AI辅助布局布线的相关研究与开发工作。</p>
          </li>

          <li className='odd:bg-gray-100 bg-white p-4 desktop:p-16 desktop:px-64'>
            <p className='py-2 font-bold desktop:text-xl'>核心需求</p>
            <p className='py-1 leading-8 desktop:text-base desktop:leading-loose'>硬件加速器集群采用国产EDA软件，利用CPU和GPU实现高速并行SPICE电路模型仿真。支持AI-EDA工具的开发工作，目前已部署了如CircuitNet，GraphSAGE等模型，进行人工智能辅助布局布线的相关研究与开发工作。</p>
            <p className='py-1 leading-8 desktop:text-base desktop:leading-loose'>
              <ol className='list-decimal list-inside pl-2 space-y-2'>
                <li>计算能力：AI辅助布局布线通常涉及大规模的数据处理和计算任务。例如需要处理大量的电路设计数据、特征提取、模型训练和优化算法等。因此，较高性能的计算设备，如支持多核心处理器或GPU（图形处理器）可以提供更快的计算速度和更高的效率。</li>
                <li>内存容量：在AI辅助布局布线中，可能需要加载和处理大量的电路布局布线数据、模型参数和中间结果。因此，较大的内存容量可以提供足够的空间来存储和处理这些数据，以保证计算的顺利进行。</li>
                <li>存储空间：AI辅助布局布线过程中会产生大量的中间结果、模型文件和输出数据。因此，需要足够的存储空间来保存这些数据。此外，较快的存储设备如固态硬盘（SSD），可以加快数据访问和存取。</li>
                <li>并行计算能力：AI辅助布局布线中的一些计算任务，如模型训练和优化算法，可以通过GPU并行计算加速来提供更高的计算效率和性能。</li>
              </ol>
            </p>
          </li>

          <li className='odd:bg-gray-100 bg-white p-4 desktop:p-16 desktop:px-64'>
            <p className='py-2 font-bold desktop:text-xl'>解决方案</p>

            <p className='py-1 leading-8 desktop:text-base desktop:leading-loose'>
              <ul className='list-disc list-inside pl-2 space-y-4'>
                <li>
                  <span className='font-bold'>结构示意图</span>

                  <div className='p-4'>
                    <Image className='h-full object-cover' src={store.config.loadImg('/resources/cover-11.png')} />
                  </div>
                </li>

                <li>
                  <span className='font-bold'>硬件加速集群节点（单节点配置）</span>

                  <div className='desktop:p-4 desktop:py-16 flex flex-col desktop:flex-row items-center justify-center desktop:space-x-2'>
                    <div className='p-4'>
                      <Image className='h-32 desktop:h-64 object-cover' src={store.config.loadImg('/resources/cover-12.png')} />
                    </div>

                    <ol className='list-decimal list-inside p-4 text-xs desktop:text-sm space-y-2'>
                      <li>CPU: 38核，76线程，2.4Ghz；</li>
                      <li>内存：1TB RECC DDR4 3200MHz;</li>
                      <li>数据盘：2*960GB SATA企业级SSD（RAID 1）；</li>
                      <li>系统盘：3*18TB SATA企业级HDD（RAID 5）；</li>
                      <li>GPU：4* Nvidia A100 40G PCIE；</li>
                      <li>2*25G光口，2*10G电口；</li>
                      <li>2000W（2+2）冗余电源 钛级（96%+）；</li>
                    </ol>
                  </div>
                </li>

                <li>
                  <span className='font-bold'>整机集成4张Nvidia A100 40G GPU计算卡，单张浮点计算能力</span>

                  <div className='desktop:p-4'>
                    <table className='mt-8 w-full table-auto border-collapse text-center text-base'>
                      <tr className='bg-blue-900 text-white font-bold'>
                        <td className='border border-gray-300 p-4'>FP64</td>
                        <td className='border border-gray-300 p-4'>9.7 TFLOPS</td>
                      </tr>

                      <tr className='bg-white text-gray-800'>
                        <td className='border border-gray-300 p-4 font-bold'>FP64 Tensor Core</td>
                        <td className='border border-gray-300 p-4'>19.5 TFLOPS</td>
                      </tr>

                      <tr className='bg-blue-100 text-gray-800'>
                        <td className='border border-gray-300 p-4 font-bold'>FP32</td>
                        <td className='border border-gray-300 p-4'>19.5 TFLOPS</td>
                      </tr>

                      <tr className='bg-white text-gray-800'>
                        <td className='border border-gray-300 p-4 font-bold'>Tensor Float 32(TF32)</td>
                        <td className='border border-gray-300 p-4'>156 TFLOPS| 312 TFLOPS*</td>
                      </tr>

                      <tr className='bg-blue-100 text-gray-800'>
                        <td className='border border-gray-300 p-4 font-bold'>BFLOAT16 Tensor Core</td>
                        <td className='border border-gray-300 p-4'>312 TFLOPS| 624 TFLOPS*</td>
                      </tr>

                      <tr className='bg-white text-gray-800'>
                        <td className='border border-gray-300 p-4 font-bold'>FP16 Tensor Core</td>
                        <td className='border border-gray-300 p-4'>312 TFLOPS 624 TFLOPS*</td>
                      </tr>

                      <tr className='bg-blue-100 text-gray-800'>
                        <td className='border border-gray-300 p-4 font-bold'>INT8 Tensor Core</td>
                        <td className='border border-gray-300 p-4'>624 TFLOPS|1248 TFLOPS*</td>
                      </tr>
                    </table>
                  </div>
                </li>

                <li>
                  <span className='font-bold'>Empyrean ALPS-GT异构加速器</span>

                  <div className='desktop:p-4 desktop:py-16 flex flex-col desktop:flex-row items-center justify-center desktop:space-x-2'>
                    <div className='p-4'>
                      <Image className='h-32 desktop:h-64 w-full object-cover' src={store.config.loadImg('/resources/cover-14.png')} />
                    </div>

                    <ol className='list-decimal list-inside p-4 text-xs desktop:text-sm space-y-2'>
                      <li>CPU: 双路处理器，单颗38核/76线程，2.4Ghz；</li>
                      <li>内存：2TB RECC DDR4 3200MHz（32*64GB）；</li>
                      <li>数据盘：2*960GB SATA企业级SSD（RAID1）；</li>
                      <li>系统盘：4*7.68TB U.2 NVMe SSD企业级；</li>
                      <li>GPU：NVIDIA HGX A100 8-GPU；</li>
                      <li>2*25G光口，2*10G电口；</li>
                      <li>4* 2200W冗余电源（96%+）；</li>
                    </ol>
                  </div>
                </li>

                <li>
                  <span className='font-bold'>整机集成8张Nvidia Nvlink HGX-A100GPU计算卡，单张浮点计算能力</span>

                  <div className='desktop:p-4'>
                    <table className='mt-8 w-full table-auto border-collapse text-center text-base'>
                      <tr className='bg-blue-900 text-white font-bold'>
                        <td className='border border-gray-300 p-4'>FP64</td>
                        <td className='border border-gray-300 p-4'>9.7 TFLOPS</td>
                      </tr>

                      <tr className='bg-white text-gray-800'>
                        <td className='border border-gray-300 p-4 font-bold'>FP64 Tensor Core</td>
                        <td className='border border-gray-300 p-4'>19.5 TFLOPS</td>
                      </tr>

                      <tr className='bg-blue-100 text-gray-800'>
                        <td className='border border-gray-300 p-4 font-bold'>FP32</td>
                        <td className='border border-gray-300 p-4'>19.5 TFLOPS</td>
                      </tr>

                      <tr className='bg-white text-gray-800'>
                        <td className='border border-gray-300 p-4 font-bold'>Tensor Float 32(TF32)</td>
                        <td className='border border-gray-300 p-4'>156 TFLOPS| 312 TFLOPS*</td>
                      </tr>

                      <tr className='bg-blue-100 text-gray-800'>
                        <td className='border border-gray-300 p-4 font-bold'>BFLOAT16 Tensor Core</td>
                        <td className='border border-gray-300 p-4'>312 TFLOPS| 624 TFLOPS*</td>
                      </tr>

                      <tr className='bg-white text-gray-800'>
                        <td className='border border-gray-300 p-4 font-bold'>FP16 Tensor Core</td>
                        <td className='border border-gray-300 p-4'>312 TFLOPS 624 TFLOPS*</td>
                      </tr>

                      <tr className='bg-blue-100 text-gray-800'>
                        <td className='border border-gray-300 p-4 font-bold'>INT8 Tensor Core</td>
                        <td className='border border-gray-300 p-4'>624 TFLOPS|1248 TFLOPS*</td>
                      </tr>
                    </table>
                  </div>
                </li>

                <li>
                  <span className='font-bold'>异构加速方案特点</span>

                  <ul className='list-disc list-inside pl-2 space-y-4'>
                    <li>
                      <span className='font-bold underline'>SPICE 电路仿真</span>

                      <ol className='list-decimal list-outside pl-4 space-y-2'>
                        <li>＞100M器件规模的仿真容量</li>
                        <li>独有CPU/GPU并行仿真技术，对基于CPU的商用并行SPICE仿真器能够达到10倍加速比</li>
                        <li>支持数模混合仿真以及与业界领先数字仿真器的co-sim</li>
                        <li>对于电源管理类电路，具有卓越的收敛性和性能</li>
                        <li>完善的电路自动静态和动态检查，帮助发现潜在的设计问题</li>
                        <li>支持 Save/Recover断点续仿功能</li>
                        <li>支持最新的5nm工艺</li>
                      </ol>
                    </li>

                    <li>
                      <span className='font-bold underline'>SMS-GT矩阵求解器</span>

                      <ol className='list-decimal list-outside pl-4 space-y-2'>
                        <li>独创的基于GPU的矩阵求解方法</li>
                        <li>充分利用GPU算力资源，更高的仿真性能□智能矩阵求解器，精度无损，性能相对CPU并行矩阵求解器可达到10倍以上加速比</li>
                      </ol>
                    </li>
                  </ul>

                  <div className='desktop:p-4 pt-2'>
                    <table className='mt-8 w-full table-auto border-collapse text-center text-base'>
                      <tr className='bg-blue-900 text-white font-bold'>
                        <td className='border border-gray-300 p-4'>测例</td>
                        <td className='border border-gray-300 p-4'>CPU-Tools（小时）</td>
                        <td className='border border-gray-300 p-4'>硬件加速集群仿真（小时）</td>
                        <td className='border border-gray-300 p-4'>加速比</td>
                      </tr>

                      <tr className='bg-white text-gray-800'>
                        <td className='border border-gray-300 p-4 font-bold'>Transmitter</td>
                        <td className='border border-gray-300 p-4'>157.4</td>
                        <td className='border border-gray-300 p-4'>18.1</td>
                        <td className='border border-gray-300 p-4'>8.7X</td>
                      </tr>

                      <tr className='bg-blue-100 text-gray-800'>
                        <td className='border border-gray-300 p-4 font-bold'>Serdes Vco</td>
                        <td className='border border-gray-300 p-4'>135.1</td>
                        <td className='border border-gray-300 p-4'>9.8</td>
                        <td className='border border-gray-300 p-4'>13.8X</td>
                      </tr>

                      <tr className='bg-white text-gray-800'>
                        <td className='border border-gray-300 p-4 font-bold'>ADC</td>
                        <td className='border border-gray-300 p-4'>174.1</td>
                        <td className='border border-gray-300 p-4'>16.3</td>
                        <td className='border border-gray-300 p-4'>10.7X</td>
                      </tr>

                      <tr className='bg-blue-100 text-gray-800'>
                        <td className='border border-gray-300 p-4 font-bold'>Serdes-TX</td>
                        <td className='border border-gray-300 p-4'>2752.8</td>
                        <td className='border border-gray-300 p-4'>12.0</td>
                        <td className='border border-gray-300 p-4'>22.9X</td>
                      </tr>
                    </table>
                  </div>
                </li>
              </ul>
            </p>
          </li>

          <li className='odd:bg-gray-100 bg-white p-4 desktop:p-16 desktop:px-64'>
            <p className='py-2 font-bold desktop:text-xl'>总结</p>
            <p className='py-1 leading-8 desktop:text-base desktop:leading-loose'>人工智能辅助布局布线在电路设计领域具有广阔的应用前景。通过结合人工智能技术和电路设计需求，研究人员可以开发出更高效、智能化的布局布线方法，为电子设计提供更好的支持和解决方案。</p>
            <p className='py-1 leading-8 desktop:text-base desktop:leading-loose'>在昊源诺信的帮助下，该工业大学通过算力升级，有效实现了向全新AI智能布局规划的迭代，进一步满足开发者对设计质量和时间的双重需求。</p>
          </li>
        </ul>
      </article>
    }],
  ])

  return <main>
    <ul className='p-4 bg-white flex flex-wrap items-center divide-x desktop:hidden'>
      {store.config.getConstantValues('Program').map(elm => {
        return <li
          key={elm.value}
          onClick={() => store.navigate.go(`/program/${elm.value}`)}
          className={`px-2 my-2 ${route.params.id == elm.value ? 'font-bold text-blue-800' : ''}`}
        >
          {elm.description}
        </li>
      })}
    </ul>

    <Image className='w-full h-auto object-cover' src={store.config.loadImg('/resources/banner-3.jpg')} />

    <ul className='bg-white text-lg items-center justify-center hidden desktop:flex'>
      {store.config.getConstantValues('Program').map(elm => {
        return <li
          key={elm.value}
          onClick={() => store.navigate.go(`/program/${elm.value}`)}
          className={`px-8 py-4 cursor-pointer text-center hover:text-blue-800 ${route.params.id == elm.value ? 'text-blue-800' : ''}`}
        >
          {elm.description}
        </li>
      })}
    </ul>

    <section>
      {getContent(parseInt(route.params.id))}
    </section>
  </main>
}
